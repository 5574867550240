<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-xl font-bold mr-3">Transactions</h2>
      </div>
      <div class="col-span-1">
        <search-form
          placeholder="Search order no, branch...."
          class="mb-5"
          v-model="transactions.query"
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>
    <div class="border border-solid border-blue-200">
      <div class="flex flex-col">
        <div class="duration-300">
          <datatable
            :url="transactions.url"
            :ajax="true"
            :ajaxPagination="true"
            :columns="transactions.columns"
            :query="transactions.query"
            :fillable="false"
            ref="table"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profile: {
      type: [Array, Object],
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      transactions: this.$options.resource([], {
        url: `${this.$baseurl}/admin/merchants/${this.profile?.id}/orders`,
        query: '',
        columns: [
          {
            name: 'order_no',
            th: 'Order No'
          },
          {
            name: 'channel',
            th: 'Payment Channel',
            render: ({ channel }) =>
              this.$options.filters?.fromSlug(channel) ?? 'In App'
          },
          {
            name: 'user',
            th: 'Customer',
            render: ({ user }) => `${user?.name} ${user?.last_name}`
          },
          {
            name: 'phone_no',
            th: 'Phone Num',
            render: ({ user }) => `${user?.phone_no}` || '----'
          },
          {
            name: 'item',
            th: 'Item'
          },
          {
            name: 'description',
            th: 'Description'
          },
          {
            name: 'price',
            th: 'Amount',
            render: transaction =>
              this.$options.filters.formatAmount(transaction?.price)
          },
          {
            name: 'branch',
            th: 'Branch',
            render: ({ branch }) => branch?.name ?? '----'
          },
          {
            name: 'status',
            th: 'Payment Status',
            render: (transaction, status) => this.getStatusBadge(status)
          },
          {
            name: 'createdAt',
            th: 'Date',
            render: transaction =>
              this.$moment(transaction?.created_at).format(
                'dddd, MMMM Do YYYY, h:mm:ss a'
              )
          }
        ]
      })
    };
  },
  methods: {
    async merchantTransactions() {
      this.transactions.loading = true;
      await this.sendRequest(
        'admin.merchants.transactions.merchantOrders',
        this.profile?.id,
        {
          success: response => {
            this.transactions.data = response.data.data;
          },
          error: error => {
            console.log(error);
          }
        }
      );
      this.transactions.loading = false;
    },
    getStatusBadge(status) {
      switch (status) {
        case 'pending':
          return `<div class="badge badge-orange-soft-outline px-7">
                  Pending
                </div>`;
        case 'processing':
          return `<div class="badge badge-blue-soft-outline px-7">
                  Processing
                </div>`;
        case 'cancelled':
          return `<div class="badge badge-red-soft-outline px-7">
                  Cancelled
                </div>`;
        case 'confirmed':
        case 'disbursed':
        case 'success':
        case 'delivered':
          return `<div class="badge badge-green-soft-outline px-7">
                  ${status}
                </div>`;
        default:
      }
    }
  }
};
</script>
